import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { getSearchPath } from "../utils/path"
import { Link } from "./link"
import linkSwitch from "../utils/linkSwitch"
import LanguageSwitcher from "./languageSwitcher"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { useSecondaryMenu } from "../hooks/useSecondaryMenu"
import { i18nContext } from "../context/i18nContext"
import { debounce } from "lodash"

const SecondaryNav = () => {
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useSecondaryMenu()

  return (
    <i18nContext.Consumer>
      {t => (
        <Box
          as="nav"
          sx={{
            position: "relative",
            zIndex: 10,
            backgroundColor: "primary",
          }}
        >
          <Container variant="header" sx={{ paddingX: [3, 4] }}>
            <Flex sx={{ flexDirection: "row", justifyContent: "flex-end" }}>
              <Flex
                sx={{
                  flexDirection: "row",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                }}
                as="ul"
              >
                {menu.map(item => (
                  <TextComponent item={item} locale={locale} key={item.id} />
                ))}
              </Flex>
              <Flex
                sx={{
                  flexDirection: "row",
                  padding: 3,
                  margin: 0,
                  listStyle: "none",
                }}
              >
                <Link
                  href={getSearchPath(locale)}
                  sx={{
                    color: "light",
                    fontSize: 1,
                    textDecoration: "none",
                    "&:hover": {
                      color: "dark",
                    },
                  }}
                >
                  {t.search}
                </Link>
                <LanguageSwitcher />
              </Flex>
            </Flex>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

const TextComponent = ({ item, locale }) => {
  // console.log(item)
  const [show, setShow] = useState(false)

  const debouncedHandleMouseEnterMenu = debounce(index => {
    setShow(true)
  }, 50)

  const handlOnMouseLeaveMenu = () => {
    setShow(false)
    debouncedHandleMouseEnterMenu.cancel()
  }

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        position: "relative",
        marginX: 1,
        paddingX: 2,
        paddingY: 3,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
      }}
      onMouseEnter={() => debouncedHandleMouseEnterMenu()}
      onMouseLeave={() => handlOnMouseLeaveMenu()}
    >
      {item.link ? (
        <Box
          sx={{
            fontSize: 1,
            "& > a": {
              color: "light",
              textDecoration: "none",
              "&:hover": {
                color: "dark",
              },
            },
          }}
        >
          {linkSwitch(item.link, locale, "secondaryNav")}
        </Box>
      ) : (
        <Box sx={{ cursor: "default", color: "light", fontSize: 1 }}>
          {item.anchor}
        </Box>
      )}
      {item.treeChildren.length > 0 && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            padding: 3,
            margin: 0,
            backgroundColor: "primary",
            position: "absolute",
            top: 45,
            width: "max-content",
            boxShadow:
              "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
            display: show ? "block" : "none",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box
                as="li"
                key={item.id}
                sx={{
                  mt: 2,
                  mb: 2,
                  fontSize: 1,
                  a: {
                    color: "light",
                    textDecoration: "none",
                    "&:hover": {
                      color: "dark",
                    },
                  },
                }}
              >
                {item.link ? (
                  linkSwitch(item.link, locale, "secondaryNav")
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default SecondaryNav

import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { getHomePath } from "../utils/path"
import { Link } from "./link"
import linkSwitch from "../utils/linkSwitch"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import logo from "../images/logo-ab.svg"
import { Image } from "@theme-ui/components"
import { ChevronDown, Menu, X } from "react-feather"
import { useSecondaryMenu } from "../hooks/useSecondaryMenu"
import LanguageSwitcherMobile from "./languageSwitcherMobile"

const MobileNav = ({ theme }) => {
  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()
  const secondaryMenu = useSecondaryMenu()

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box>
        <Container sx={{ backgroundColor: "light", paddingY: [2, 3] }}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Link to={getHomePath(locale)}>
                <Image src={logo} sx={{ width: "160px" }} />
              </Link>
            </Box>
            <Box onClick={() => setShow(!show)}>
              <Menu />
            </Box>
          </Flex>
        </Container>
      </Box>
      {show && (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "dark",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1000,
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Container sx={{ backgroundColor: "light", paddingY: [2, 3] }}>
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <Link to={getHomePath(locale)}>
                  <Image src={logo} sx={{ width: "160px" }} />
                </Link>
              </Box>
              <Box onClick={() => setShow(!show)}>
                <X />
              </Box>
            </Flex>
          </Container>
          <Flex
            sx={{
              flexDirection: "column",
              padding: 0,
              paddingY: 3,
              margin: 0,
              listStyle: "none",
            }}
            as="ul"
          >
            <Box
              sx={{
                paddingX: 3,
                paddingY: 3,
                color: "primary",
                fontWeight: "bold",
              }}
            >
              AB for
            </Box>
            {menu.map(item => (
              <TextComponent item={item} locale={locale} key={item.id} />
            ))}
          </Flex>
          <Box
            as="ul"
            sx={{
              backgroundColor: "primary",
              padding: 0,
              paddingY: 3,
              margin: 0,
              listStyle: "none",
            }}
          >
            {secondaryMenu.map(item => (
              <TextComponent item={item} locale={locale} key={item.id} />
            ))}
          </Box>
          <LanguageSwitcherMobile />
        </Box>
      )}
    </Box>
  )
}

const TextComponent = ({ item, locale, theme }) => {
  const [show, setShow] = useState(false)
  const componentTheme = theme || "light"
  // console.log(componentTheme, "componentTheme")
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        position: "relative",
        fontWeight: "normal",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a": {
          color: "light",
          paddingX: 3,
          paddingY: [3, 2],
          display: "block",
        },
      }}
      onClick={() => setShow(!show)}
    >
      {item.link ? (
        linkSwitch(item.link, locale, "mobileNav")
      ) : (
        <Flex
          sx={{
            cursor: "default",
            paddingX: 3,
            paddingY: [3, 2],
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>{item.anchor}</Box>
          <Box>
            <ChevronDown size={16} />
          </Box>
        </Flex>
      )}
      {item.treeChildren.length > 0 && show && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            padding: 3,
            margin: 0,
            backgroundColor: "darkBackground",
            position: "relative",
            width: "100%",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box
                as="li"
                key={item.id}
                sx={{
                  mt: 2,
                  mb: 2,
                  a: {
                    textDecoration: "none",
                    color: "light",
                  },
                }}
              >
                {item.link ? (
                  linkSwitch(item.link, locale, "mobileNav")
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default MobileNav
